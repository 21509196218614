import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Incidencia } from '../models/incidencia';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
  })
  export class IncidenciasService {

    constructor(private http: HttpClient, private authService: AuthService) {}

    getQuery( params: HttpParams ) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const url_api = 'https://hr.miplanning.es/index.php';
        // const url_api = 'http://localhost/estrella/index.php';

        return this.http.get(url_api, { headers: headers, params});
    }

    getIncidencias(fechaInicio: string, fechaFin: string) {
        const token = this.authService.getToken();
        const params = new HttpParams()
            .set('modulo', 'incidencia_ws')
            .set('op', 'verIncidencias')
            .set('q', 'mip/ajax')
            .set('fechaInicio', fechaInicio)
            .set('fechaFin', fechaFin)
            .set('token', token);

        return this.getQuery(params)
            .pipe(map(data => data['incidenciasTrabajador'].incidencias));
    }

    getVacaciones(fechaInicio: string, fechaFin: string) {
        const token = this.authService.getToken();
        const params = new HttpParams()
            .set('modulo', 'incidencia_ws')
            .set('op', 'verIncidencias')
            .set('q', 'mip/ajax')
            .set('fechaInicio', fechaInicio)
            .set('fechaFin', fechaFin)
            .set('token', token);

        return this.getQuery(params)
            .pipe(map(data => data['incidenciasTrabajador'].vacaciones));
    }

    getIncidenciasTipo() {
        const token = this.authService.getToken();
        const params = new HttpParams()
            .set('modulo', 'incidenciaTipo_ws')
            .set('op', 'verIncidenciasTipo')
            .set('q', 'mip/ajax')
            .set('token', token);

        return this.getQuery(params)
            .pipe(map(data => data['incidenciasTipo']));
    }
}
