import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Buzon } from '../models/buzon';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
  })
  export class NotificacionesService {

    constructor(private http: HttpClient, private authService: AuthService) {}

    getQuery( params: HttpParams ): Observable<Buzon[]> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const url_api = 'https://hr.miplanning.es/index.php';
        // const url_api = 'http://localhost/estrella/index.php';
        return this.http.get<Buzon[]>(url_api, { headers: headers, params});
    }

    getMensajes() {
        const token = this.authService.getToken();

        const params = new HttpParams()
        .set('modulo', 'buzon_ws')
        .set('op', 'verNotificaciones')
        .set('q', 'mip/ajax')
        .set('token', token);

        return this.getQuery(params)
            .pipe(map(data => data['mensajes']));
    }

    updateMensaje(idmensaje) {
        const token = this.authService.getToken();
        const params = new HttpParams()
        .set('modulo', 'buzon_ws')
        .set('op', 'actualizarNotificacion')
        .set('q', 'mip/ajax')
        .set('idmensaje', idmensaje)
        .set('token', token);

        return this.getQuery(params)
            .pipe(map(data => data));
    }
}
